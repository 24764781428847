<template>
  <div class="home pages-store">
    <div class="flex-sw-center" style="height: 50px; width: 100%">
      <div class="flex-start-centere">
        <el-input
          placeholder="所属主账号"
          v-model.trim="parmes.mainUserName"
          style="width: 180px; margin-right: 15px"
          @keydown.enter.native="getMessage()"
        ></el-input>
        <el-input
          placeholder="用户名"
          v-model.trim="parmes.username"
          style="width: 180px; margin-right: 15px"
          @keydown.enter.native="getMessage()"
        ></el-input>
        <el-button
          class="h30"
          style="margin-left: 15px"
          type="success"
          size="mini"
          icon="el-icon-search"
          @click="getMessage(true)"
        >
          查询
        </el-button>
        <el-button
          class="h30"
          type="warning"
          size="mini"
          icon="el-icon-refresh"
          @click="
            reSetParmes();
            getMessage(true);
          "
        >
          重置
        </el-button>
      </div>
    </div>

    <div-table
      :loading="loading"
      :fromList="fromList"
      :fromData="fromData"
      :pageSize="pageSize"
      :pageValue="pageValue"
      :pageSizeList="pageSizeList"
      :total="total"
      @up-page-size="pageSize = $event"
      @up-page-value="pageValue = $event"
      @get-message="getMessage($event)"
    >
    </div-table>
  </div>
</template>

<script>
import divTable from "../components/div-table.vue";
export default {
  components: {
    divTable,
  },
  data() {
    return {
      uploadUrl: "",
      parmes: {},
      loading: false,
      fromList: [
        {
          label: "ID",
          key: "id",
          disabled: true,
        },
        {
          label: "用户名",
          key: "userName",
          slot: "copy",
        },
        {
          label: "所属主账号",
          key: "mainUserName",
          slot: "copy",
        },
        {
          label: "标签颜色",
          key: "color",
          slot: "copy",
          width: '170px',
          trans: (val) => {
            return val.color
              ? `
              color: #fff;
              position: relative;
              top: 0;
              left: 0;
              height: 100%;
              background-color: ${val.color}
            `
              : "";
          },
        },
        {
          label: "标签名称",
          key: "name",
          slot: "copy",
        },
        {
          label: "创建时间",
          key: "createTime",
        },
        {
          label: "修改时间",
          key: "updateTime",
        },
      ],
      fromData: [],
      pageSize: 15,
      pageSizeList: [3, 15, 200, 300, 400],
      pageValue: 1,
      total: 0,
    };
  },
  created() {},
  async mounted() {
    this.getMessage();
  },

  watch: {},
  methods: {
    showPageList(arr, filterateArr) {
      let newArr = [];
      arr.forEach((item) => {
        filterateArr.indexOf(item.key) != -1 ? newArr.push(item) : "";
      });
      return newArr;
    },
    reSetParmes() {
      this.parmes = {};
    },
    async getMessage(boole = false) {
      if (boole) {
        this.pageValue = 1;
      }
      this.loading = true;
      const {
        code,
        data: { rows, total },
        msg,
      } = await this.$apis
        .get(`/admin_label`, {
          page_index: this.pageValue,
          page_size: this.pageSize,
          ...this.parmes,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        // this.fromData = rows.filter((x) => x.status == 1);
        this.fromData = rows;
        this.total = total || 0;
      } else {
        this.$message.error(msg);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  background-color: #fff;
}
.operate {
  justify-content: space-around;
}
.h30 {
  height: 30px;
}
.avatar {
  width: 36px;
}
</style>
