<template>
  <div class="home pages-store">
    <div>
        <div style="font-size: 18px;" class="self_info">
          您的提成比例为 <span style="color: red">{{selfInfo.prize_rate}}%</span>
        </div>
        <p class="regUrl" style="margin-bottom: 0px;font-size:16px">
          您的专属推广链接：<a style="color: #1682e6;">https://lumibrowser.com/#{{selfInfo.invitation_code}}</a>
          <i class="el-icon-document-copy" style="color: blue;margin-left: 3px;cursor: pointer;"
            @click="clipboardText(`https://lumibrowser.com/#${selfInfo.invitation_code}`)">
          </i>
          <br />
          凡是通过您链接注册进来的客户，您能看到名下所有账户的注册信息。
        </p>
    </div>

    <div style="margin-top: 30px;font-weight: 700;">每月注册信息</div>
    <el-table
          :data="tableData1"
          max-height="800"
          style="width: 100%">
          <el-table-column
            prop="month"
            label="月份"
            >
          </el-table-column>
          <el-table-column
            prop="user_cnt"
            label="用户数量"
            >
          </el-table-column>
          <el-table-column
            prop="prize_amount"
            label="提成金额(美元)">
          </el-table-column>
          <el-table-column
            prop="prize_time"
            label="提成发放时间">
          </el-table-column>
        </el-table>

          </div>
</template>

<script>
import { clipboardText } from '@/utils/index'

export default {
  data() {
    return {
      tableData1: [],
      selfInfo: {}
    }
  },
  mounted() {
    this.getCommissionList()
    this.getCommissionInfo()
  },
  methods: {
    clipboardText,
    getCommissionList() {
      this.$apis.get('/admin_get_self_by_month').then(res => {
        this.tableData1 = res.data;
      })
    },
    getCommissionInfo() {
      this.$apis.get('/admin_get_self_info').then(res => {
        this.selfInfo = res.data;
      })
    },
  }
}
</script>
