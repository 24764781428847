<template>
  <el-dialog title="升级列表" width="80%" :visible.sync="_visible">
    <div class="flex-start-center">
      <el-input style="width: 180px" placeholder="插件名称" v-model.trim="parmes.plugin_name"
        @keydown.enter.native="getMessage(true)"></el-input>
      <el-button style="margin-left: 15px" type="success" size="mini" icon="el-icon-search"
        @click="getMessage(true)">查询</el-button>
      <el-button type="warning" size="mini" icon="el-icon-refresh" @click="reSet">重置</el-button>
    </div>

    <div-table H="550px" :loading="loading" :fromList="fromList" :fromData="fromData" :pageSize="pageSize"
      :pageValue="pageValue" :pageSizeList="pageSizeList" :total="total" @up-page-size="pageSize = $event"
      @up-page-value="pageValue = $event" @get-message="getMessage($event)">
      <template #operate="{ data }">
        <el-button type="primary" @click="onUpgrade(data.rows)" :loading="updating[data.rows.id]">升级</el-button>
      </template>
    </div-table>

  </el-dialog>
</template>

<script>
import divTable from "@/views/huoshan/components/div-table.vue";
const columns = [
  {
    label: `插件ID`,
    key: `pluginDir`,
    width: "300px",
    slot: 'copy'
  },
  {
    label: `插件名称`,
    key: `pluginName`,
    width: "260px",
  },
  {
    label: `最新版本`,
    key: `newPluginVersion`,
  },
  {
    label: `当前版本`,
    key: `oldPluginVersion`,
  },
  {
    label: `更新时间`,
    key: `updateTime`,
  },
  {
    label: `操作`,
    key: `operate`,
    slot: `operate`,
    width: "120px",
  }
]

export default {
  name: "PlugUpgrade",
  components: { divTable },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    }
  },
  computed: {
    _visible: {
      get ({ visible }) {
        return visible
      },
      set (value) {
        this.$emit("update:visible", value)
      }
    }
  },
  data () {
    return {
      loading: false,
      parmes: { plugin_name: '' },
      fromList: columns,
      fromData: [],
      pageSize: 15,
      pageSizeList: [3, 15, 200, 300, 400],
      pageValue: 1,
      updating: {},
    }
  },
  async mounted () {
    this.getMessage();
  },
  methods: {
    reSet () {
      this.parmes = { plugin_name: "" }
      this.getMessage(true)
    },
    async getMessage (boole = false) {
      if (boole) {
        this.pageValue = 1;
      }
      this.loading = true;
      const {
        code,
        data: { rows, total },
        msg,
      } = await this.$apis
        .get(`/admin_user_new_plugin_info_list`, {
          page_index: this.pageValue,
          page_size: this.pageSize,
          ...this.parmes,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        this.fromData = rows;
        this.$emit("update:total", total);
      } else {
        this.$message.error(msg);
      }
    },
    async onUpgrade (item) {
      this.$set(this.updating, item.id, true)
      const { code, msg } = await this.$apis
        .post(`/admin_user_update_plugins`, { pluginDir: item.pluginDir })
        .catch(e => ({ code: 1 }))

      this.$set(this.updating, item.id, false)

      if (code == 0) {
        this.$message.success(msg)
        this.getMessage()
      } else {
        this.$message.error(msg)
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>