<template>
  <div>
    <template v-if="data">
      <panel-group :sys-info="data" />
      <template v-for="item in lineChartList">
        <el-row style="background:#fff;padding:16px 16px 0;margin-bottom:32px;">
          <line-chart :config="item.config" :updateSize="$attrs.updateSize" />
        </el-row>
      </template>
    </template>
  </div>
</template>

<script>
import PanelGroup from './components/PanelGroup'
import LineChart from './components/LineChart'
import { getUserRegisterLineData, getWindowCreateLineData, getRechargeLineData } from "./lineData";

const lineChartFormatter = [
  {
    title: '近1个月内新增用户',
    dataKey: 'user_add_latest30_list',
    formatter: getUserRegisterLineData
  },
  {
    title: '近1个月内新增窗口',
    dataKey: 'window_add_latest30_list',
    formatter: getWindowCreateLineData
  },
  {
    title: '近1个月内收入',
    dataKey: 'recharge_latest30_list',
    formatter: getRechargeLineData
  }
]
export default {
  name: 'PanelOverview',
  components: {
    PanelGroup,
    LineChart,
  },
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  computed: {
    lineChartList ({ data }) {
      return !data
        ? []
        : lineChartFormatter.map(item => ({
          title: item.title,
          config: item.formatter(item.title, data[item.dataKey].reverse())
        }))
    },
  },
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped></style>
