<template>
  <div class="home pages-store">
    <div class="flex-sw-center" style="height: 50px; width: 100%">
      <div class="flex-start-center">
        <el-select
          placeholder="用户类型"
          v-model="parmes.role"
          filterable
          clearable
          @change="getMessage(true)"
        >
          <el-option
            v-for="(item, index) in [
              {
                label: `全部`,
                value: '',
              },
              ...Object.keys(checkMap).map((key) => ({
                label: checkMap[key].name,
                value: key,
              })),
            ]"
            :key="index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input
          placeholder="所属主账号"
          v-model.trim="parmes.mainUserName"
          style="width: 180px; margin-left: 15px"
          @keydown.enter.native="getMessage(true)"
        ></el-input>
        <el-input
          placeholder="用户名"
          v-model.trim="parmes.username"
          style="width: 180px; margin-left: 15px"
          @keydown.enter.native="getMessage(true)"
        ></el-input>
        <el-button
          class="h30"
          style="margin-left: 15px"
          type="success"
          size="mini"
          icon="el-icon-search"
          @click="getMessage(true)"
        >
          查询
        </el-button>
        <el-button
          class="h30"
          type="warning"
          size="mini"
          icon="el-icon-refresh"
          @click="
            reSetParmes();
            getMessage(true);
          "
        >
          重置
        </el-button>
      </div>

      <div class="flex-start-center" style="margin-left: 20px">
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-plus"
          @click="
            fromInit();
            from_type = `add`;
            show = true;
          "
        >
          新增
        </el-button>
      </div>
    </div>

    <div-table
      ref="userTableRef"
      :H="null"
      :loading="loading"
      :fromList="fromList"
      :fromData="fromData"
      :pageSize="pageSize"
      :pageValue="pageValue"
      :pageSizeList="pageSizeList"
      :total="total"
      @up-page-size="pageSize = $event"
      @up-page-value="pageValue = $event"
      @get-message="getMessage($event)"
    >
      <template v-slot:maxWindowCount="{ data }">
        {{ data.rows.maxWindowCount ? data.rows.maxWindowCount : "-" }}
      </template>
      <template v-slot:createTime="{ data }">
        <p class="openingTime">{{ data.rows.createTime }}</p>
      </template>
      <template v-slot:operate="{ data }">
        <div class="operate flex-start-center">
          <el-link
            :underline="false"
            type="danger"
            style="margin-right: 20px"
            @click="changeList(data.rows, 'word', '留言')"
            >留言</el-link
          >
          <el-link
            :underline="false"
            type="warning"
            style="margin-right: 20px"
            @click="changeList(data.rows, 'edit', '编辑用户')"
            >编辑</el-link
          >
          <el-link
            :underline="false"
            type="danger"
            :style="`margin-right: 20px;color:${
              data.rows.status == 1 ? 'red' : '#2692ff'
            }`"
            @click="changeList(data.rows, 'freeze', '冻结')"
            >{{ data.rows.status == 1 ? "冻结" : "解冻" }}</el-link
          >
        </div>
        <div class="operate flex-start-center">
          <el-link
            :underline="false"
            type="primary"
            style="margin-right: 20px"
            @click="changeList(data.rows, 'recharge', '充值')"
            >充值</el-link
          >
          <el-link
            :underline="false"
            style="margin-right: 20px"
            @click="changeList(data.rows, 'detail', '充值明细')"
            >明细</el-link
          >
          <el-link
            :underline="false"
            type="success"
            style="margin-right: 20px"
            @click="changeList(data.rows, 'price', '修改单价')"
            >修改单价</el-link
          >
        </div>
      </template>
    </div-table>
    <!-- 充值明细 -->

    <el-drawer
      title="子用户详情"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
      size="50%"
    >
      <div
        v-if="drawer"
        class="flex-d"
        style="width: calc(100% - 30px); margin: 0 15px; padding: 5px 10px"
      >
        <el-descriptions
          title="详细信息"
          direction="vertical"
          border
          style="margin-bottom: 10px"
          :column="4"
        >
          <el-descriptions-item
            v-for="(item, index) in drawerList"
            :key="index"
            :label="item.label"
          >
            <el-tooltip
              class="item"
              effect="dark"
              placement="top-start"
              :content="
                item.transValue == undefined
                  ? JSON.stringify(userMessage[item.key]) || `-`
                  : JSON.stringify(
                      item.transValue(userMessage[item.key], userMessage)
                    )
              "
            >
              <span>{{
                item.transValue == undefined
                  ? userMessage[item.key] || "-"
                  : item.transValue(userMessage[item.key], userMessage)
              }}</span>
            </el-tooltip>
          </el-descriptions-item>
        </el-descriptions>
        <el-radio-group
          v-model="checkValue"
          size="mini"
          @change="getChildMessage()"
        >
          <div>
            {{ userMessage }}
          </div>
          <el-radio-button
            v-for="(value, index) in checkValueList"
            :label="value"
            :key="index"
          >
            {{ checkMap[value].name }}
          </el-radio-button>

          <div-table
            :loading="child_table.loading"
            :fromList="child_table.fromList"
            :fromData="child_table.fromData"
            :pageSize="child_table.pageSize"
            :pageValue="child_table.pageValue"
            :pageSizeList="child_table.pageSizeList"
            :total="child_table.total"
            @up-page-size="child_table.pageSize = $event"
            @up-page-value="child_table.pageValue = $event"
            @get-message="getChildMessage($event)"
            :infoPage="false"
            H="50vh"
          >
          </div-table>
        </el-radio-group>
      </div>
    </el-drawer>
    <el-dialog
      :title="from_type == 'add' ? '新增用户' : title"
      :visible.sync="show"
      width="50%"
    >
      <el-form
        lable-postition="right"
        ref="form"
        :rules="rules"
        label-width="120px"
      >
        <div v-if="from_type == 'word'">
          <el-form-item label="ID">
            <el-input
              type="text"
              style="margin-bottom: 10px"
              v-model="notifyForm.id"
              placeholder="请输入"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="留言内容">
            <el-input
              type="textarea"
              style="margin-bottom: 10px"
              v-model="notifyForm.notify"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </div>
        <div v-else>
          <el-form-item
            v-for="(value, index) in from_items"
            v-show="
              value.model == from_type ||
              ((value.model == 'id' || value.key == 'userName') &&
                from_type != 'add') ||
              (from_type == 'add'
                ? (value.model == 'edit' && !value.disabled) ||
                  value.key == 'userName'
                : false)
            "
            :label="value.label"
            :key="index"
          >
            <el-input
              v-if="
                value.model == from_type ||
                value.model == 'id' ||
                value.key == 'userName' ||
                from_type == 'add'
              "
              :type="value.type ? value.type : 'text'"
              style="margin-bottom: 10px"
              v-model="from[value.key]"
              placeholder="请输入"
              :disabled="
                value.disabled
                  ? from_type != 'add'
                    ? value.disabled
                    : value.key != 'userName'
                  : false
              "
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div
        class="flex-end-center"
        style="margin-top: 15px"
        v-show="from_type != 'detail'"
      >
        <el-button
          type="primary"
          @click="
            from_type == 'price'
              ? changePrice()
              : from_type == 'recharge'
              ? recharge()
              : submit()
          "
        >
          提交</el-button
        >
      </div>
    </el-dialog>
    <!-- 充值明细 -->
    <el-dialog width="50%" :visible.sync="detailShow">
      <div slot="title">
        <span style="color: #2692ff;">{{ from.userName }}</span>
        充值明细
      </div>
      <div-table
        :loading="rechargeLoading"
        :fromList="rechargeDetailList"
        :fromData="rechargeDetailData"
        :infoPage="false"
      >
      </div-table>
    </el-dialog>
  </div>
</template>

<script>
class tableObj {
  constructor(obj = {}) {
    obj = {
      fromList: [],
      fromData: [],
      pageSize: 15,
      pageSizeList: [3, 15, 200, 300, 400],
      pageValue: 1,
      total: 0,
      loading: false,
      ...obj,
    };

    Object.keys(obj).forEach((key) => {
      this[key] = obj[key];
    });
  }
}
import divTable from "./components/div-table.vue";
import md5 from "./coom/md5.js";

export default {
  components: {
    divTable,
  },
  data() {
    return {
      drawer: false,
      direction: "rtl",
      parmes: { role: "main" },
      // 主表格
      userMessage: {},
      loading: false,
      fromList: [
        {
          label: `ID`,
          key: `id`,
          width: `110px`,
          slot: "id",
          enableCopy: true,
          click: async (value) => {
            if (value.role == "main") {
              this.drawer = true;
              this.userMessage = value;
              this.getChildMessage();
            }
          },
          trans: (value) => {
            if (value.role == "main") {
              return `color: blue;font-weight: bold;cursor: pointer;`;
            }
          },
        },
        {
          label: `用户名`,
          key: `userName`,
          slot: "copy",
          width: '160px'
        },
        {
          label: `角色`,
          key: `role`,
          slot: `color`,
          trans: (value) => {
            return `color:${
              this.checkMap[value.role].color
            };font-weight: bold;`;
          },
          transValue: (value) => {
            return this.checkMap[value].name;
          },
        },
        {
          label: `所属主账号`,
          key: `mainUserName`,
          width: '140px'
        },

        {
          label: `可创建窗口总数`,
          key: `maxWindowCount`,
          slot: `maxWindowCount`,
        },
        {
          label: `已创建窗口总数`,
          key: `useWindowCount`,
        },
        {
          label: `已授权窗口总数`,
          key: `assignCnt`,
        },
        {
          label: `可创建员工总数`,
          key: `maxUserCount`,
        },
        {
          label: `已创建员工总数`,
          key: `useUserCount`,
        },
        {
          label: `今日可打开总数`,
          key: `maxOpenCount`,
        },
        {
          label: `今日已打开总数`,
          key: `useOpenCount`,
        },
        {
          label: `开户时间`,
          key: `createTime`,
          slot: `createTime`,
        },
        {
          label: `总充值($)`,
          key: `totalAmount`,
        },
        {
          label: `余额($) `,
          key: `leftAmount`,
        },
        // {
        //   label: `住宅IP单价`,
        //   key: `price`,
        // },
        {
          label: `操作`,
          key: `operate`,
          slot: `operate`,
          width: "180px",
        },
      ],
      fromData: [],
      pageSize: 15,
      pageSizeList: [3, 15, 200, 300, 400],
      pageValue: 1,
      total: 0,
      // 子用户信息弹窗
      checkValue: "staff",
      checkValueList: ["staff", "manager", "admin"],
      checkMap: {
        main: {
          name: "主账户",
          url: "/admin_get_main_user_by_id",
          color: "#FF4960",
        },
        admin: {
          name: "管理员",
          url: "/admin_get_main_user_by_id",
          color: "#F47700",
        },
        manager: {
          name: "经理",
          url: `/admin_get_manager_by_main_id`,
          color: "#0077C8",
        },
        staff: {
          name: "员工",
          url: "/admin_get_sub_user",
          color: "#222416",
        },
      },
      child_table: new tableObj(),
      // 操作表单
      from_type: "add",
      title: "",
      show: false,
      from: {},
      from_items: [
        {
          label: `ID`,
          key: `id`,
          model: "id",
          disabled: true,
        },
        {
          label: `用户名`,
          key: `userName`,
          model: "edit",
          disabled: true,
        },
        {
          label: `角色`,
          key: `role`,
          model: "edit",
          disabled: true,
        },
        {
          label: `所属主账号`,
          key: `mainUserName`,
          model: "edit",
          disabled: true,
        },
        {
          label: `余额($)`,
          key: `leftAmount`,
          model: "edit",
          disabled: true,
        },
        {
          label: `总充值`,
          key: `totalAmount`,
          model: "edit",
          disabled: true,
        },
        // {
        //   label: `住宅IP单价`,
        //   key: `price`,
        //   model: "edit",
        //   disabled: true,
        // },
        {
          label: "金额($)",
          key: "amount",
          model: "recharge",
        },
        {
          label: "备注",
          key: "remark",
          type: "textarea",
          model: "recharge",
        },
        {
          label: "输入密码",
          key: "password",
          model: "edit",
        },
        {
          label: "可创建窗口总数",
          key: "maxWindowCount",
          model: "edit",
        },
        {
          label: "已创建窗口总数",
          key: "useWindowCount",
          disabled: true,
        },
        {
          label: "可创建员工总数",
          key: "maxUserCount",
          model: "edit",
        },
        {
          label: "已创建员工总数",
          key: "useUserCount",
          disabled: true,
        },
        {
          label: "今日可打开总数",
          key: "maxOpenCount",
          model: "edit",
        },
        {
          label: "今日已打开总数",
          key: "useOpenCount",
          disabled: true,
        },
        {
          label: "开户时间",
          key: "createTime",
          disabled: true,
        },
        {
          label: "输入手机号",
          key: "phone",
          model: "edit",
        },
        {
          label: "输入邮箱",
          key: "email",
          model: "edit",
        },
        {
          label: "输入备注",
          key: "remark",
          type: "textarea",
          model: "edit",
        },
        {
          label: "住宅IP单价($)",
          key: "price",
          model: "price",
        },
        {
          label: "城市IP单价($)",
          key: "cityPrice",
          model: "price",
        },
        {
          label: "手机IP单价($)",
          key: "mobilePrice",
          model: "price",
        },
        {
          label: "机房IP单价($)",
          key: "dcPrice",
          model: "price",
        },
      ],
      rules: {},
      // 充值明细表
      detailShow: false,
      rechargeLoading: false,
      rechargeDetailList: [
        {
          label: `ID`,
          key: `id`,
        },
        {
          label: `充值金额($)`,
          key: `amount`,
        },
        {
          label: `备注`,
          key: `remark`,
        },
        {
          label: `充值时间`,
          key: `createTime`,
        },
      ],
      rechargeDetailData: [],
      rechargeDetailTotal: 0,
      key: "",
      drawerList: [
        {
          label: "姓名",
          key: "userName",
        },
        {
          label: "密码",
          key: "password",
        },
        {
          label: "备注",
          key: "remark",
          solt: "copy",
        },
        {
          label: "手机号",
          key: "phone",
        },
        {
          label: "邮箱",
          key: "email",
        },
        {
          label: "住宅IP单价($)",
          key: "price",
        },
        {
          label: "城市IP单价($)",
          key: "cityPrice",
        },
        {
          label: "手机IP单价($)",
          key: "mobilePrice",
        },
        {
          label: "机房IP单价($)",
          key: "dcPrice",
        },
      ],
      notifyForm: {
        id: "",
        notify: "",
      },
    };
  },
  created() {
    this.$dicts.getSystemDict();
  },
  async mounted() {
    this.getMessage();
    this.reSetParmes();
  },

  watch: {},
  methods: {
    async changeList(obj, type, title) {
      if (type == "word") {
        this.show = true;
        this.title = title;
        this.from_type = type;
        this.notifyForm.id = obj.id;
      }
      if (type != "freeze" && type != "detail") {
        this.show = true;
        this.title = title;
        this.from = obj;
        this.from_type = type;
      }
      if (type == "detail") {
        this.detailShow = true;
        this.title = title;
        this.from = obj;
        this.from_type = type;
        this.getRechargeDetail(obj.id);
      }
      if (type == "freeze") {
        const {
          code,
          data: { rows, total },
          msg,
        } = await this.$apis
          .get(
            `/${obj.status == 1 ? "admin_close_user" : "admin_active_user"}`,
            {
              id: obj.id,
            }
          )
          .catch((e) => ({
            code: 1,
          }))
          .finally(() => {
            this.loading = false;
          });
        if (code == 0) {
          this.$message.success(`成功`);
          this.getMessage();
        } else {
          this.$message.error(msg);
        }
      }
    },
    fromInit() {
      this.from = {
        password: "",
        phone: "",
        email: "",
        remark: "",
      };
    },
    async submit() {
      if (this.from_type == "word") {
        const { code, data, msg } = await this.$apis
          .post(`/admin_user_notify`, this.notifyForm)
          .catch((e) => ({
            code: 1,
          }))
          .finally(() => {
            this.loading = false;
          });
        if (code == 0) {
          this.$message.success(`成功`);
          this.getMessage();
          this.show = false;
        } else {
          this.$message.error(msg);
        }
      } else {
        const { data, code, msg } = await this.$apis
          .post(
            `/${
              this.from_type == `add` ? `admin_create_user` : `admin_mdf_user`
            }`,
            this.from
          )
          .catch((e) => ({
            code: 1,
          }))
          .finally(() => {
            this.loading = false;
          });
        if (code == 0) {
          this.$message.success(`成功`);
          this.getMessage();
          this.show = false;
        } else {
          this.$message.error(msg);
        }
      }
    },
    // 修改单价
    async changePrice() {
      let { cityPrice, dcPrice, id, mobilePrice, price } = this.from;
      cityPrice = cityPrice - 0;
      dcPrice = dcPrice - 0;
      mobilePrice = mobilePrice - 0;
      price = price - 0;
      const { data, code, msg } = await this.$apis
        .post(`/admin_mdf_main_wallet`, {
          cityPrice,
          dcPrice,
          id,
          mobilePrice,
          price,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      console.log(data, code);
      if (code == 0) {
        this.$message.success(`成功`);
        this.getMessage();
        this.show = false;
      } else {
        this.$message.error(msg);
      }
    },
    reSetParmes() {
      this.parmes = {
        username: "",
        role: `main`,
      };
    },
    handleClose(done) {
      return done();
      // this.$confirm("确认关闭？")
      //   .then((_) => {
      //     done();
      //   })
      //   .catch((_) => {});
    },
    // 获取充值key
    async recharge() {
      const { code, data, msg } = await this.$apis
        .get(`/admin_get_recharge_key`, {
          id: this.from.id,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        this.key = data;
        // this.from.signature = md5(data + localStorage.token + this.from.id + this.from.amount);
        this.from.signature = md5(data + this.from.amount * 100);
        this.addRecharge();
      } else {
        this.$message.error(msg);
      }
    },
    // 充值
    async addRecharge() {
      let obj = {
        id: this.from.id,
        amount: this.from.amount * 100,
        remark: this.from.remark,
        key: this.key,
        signature: this.from.signature,
      };
      const {
        code,
        data: { rows, total },
        msg,
      } = await this.$apis
        .get(`/admin_recharge`, {
          ...obj,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        this.$message.success("充值成功");
        this.getMessage();
        this.show = false;
      } else {
        this.$message.error(msg);
      }
    },
    // 获取充值详情
    async getRechargeDetail(id) {
      this.rechargeLoading = true;
      const {
        code,
        data: { rows, total },
        msg,
      } = await this.$apis
        .get(`/admin_get_recharge_detail`, {
          pageValue: 1,
          page_size: 15,
          id,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.rechargeLoading = false;
        });
      if (code == 0) {
        this.rechargeDetailData = rows;
        this.rechargeDetailTotal = total;
      } else {
        this.$message.error(msg);
      }
      this.rechargeLoading = false;
    },
    // 获取列表
    async getMessage(boole = false) {
      if (boole) {
        this.pageValue = 1;
      }
      this.loading = true;
      const {
        code,
        data: { rows, total },
        msg,
      } = await this.$apis
        .get(`/admin_get_main_user`, {
          page_index: this.pageValue,
          page_size: this.pageSize,
          ...this.parmes,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        this.fromData = rows;

        // let fromListMap = [];
        // Object.keys(rows[0]).forEach((key) => {
        //   fromListMap.push({
        //     label: key,
        //     key: key,
        //   });
        // });
        // this.fromList = fromListMap;
        this.total = total;
      } else {
        this.$message.error(msg);
      }

      this.loading = false;
    },

    // 获取用户信息
    async getUserMessage(id) {
      const {
        code,
        data: { user },
        msg,
      } = await this.$apis
        .get(`/admin_get_main_user_by_id`, {
          id: id,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });

      console.log(code, user);
      if (code == 0) {
        return {
          info: true,
          data: user,
        };
      } else {
        this.$message.error(msg);
      }
      return {
        info: false,
      };
    },
    // 用户用户下级信息
    async getChildMessage(boole = true) {
      let url = this.checkMap[this.checkValue].url;
      let id = this.userMessage.id;
      this.child_table.loading = true;
      let info_admin = this.checkValue == "admin";

      const { code, data } = await this.$apis.get(url, {
        id,
      });

      if (code == 0) {
        this.child_table.fromList = [];
        this.child_table.fromData = [];

        let array = info_admin ? data?.admin : data;
        let fromListMap = [];

        Object.keys(array[0] || []).forEach((key) => {
          fromListMap.push({
            label: key,
            key: key,
            slot: "copy",
          });
        });
        this.child_table.fromList = fromListMap;
        this.child_table.fromData = array;
      }
      this.child_table.loading = false;
    },
    handleSizeChange(val) {
      console.log(`${val} items per page`);
      this.pageSize = val;
      this.getMessage();
    },
    handleCurrentChange(val) {
      console.log(`current page: ${val}`);
      this.pageValue = val;
      this.getMessage();
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  background-color: #fff;
}
.h30 {
  height: 30px;
}
.openingTime {
  white-space: normal;
  margin: 0;
}
</style>
