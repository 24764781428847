<template>
  <div :class="classes">
    <div v-for="(item, index) in pieChartList" :key="index">
      <pie-chart height="500px" :config="item" @pie-click="handlePieClick" :updateSize="$attrs.updateSize" />
    </div>
    <pie-detail title="平台数据详情" :columns="columns" :data="tableData" :visible.sync="visible"
      @get-message="getMessage"></pie-detail>
  </div>
</template>

<script>
import PieChart from './components/PieChart'
import pieDetail from './components/pieDetail'
import { getVersionPieData } from "./lineData";
import { isMobile } from '@/utils';

const columns = [
  {
    key: 'userName',
    label: '用户名',
    minWidth: '150px',
  },
  {
    key: 'platform',
    label: '平台名称',
    minWidth: '150px',
  },
  {
    key: 'appVersion',
    label: 'app版本号',
    minWidth: '150px',
  },
  {
    key: 'createTime',
    label: '注册时间',
    minWidth: '250px',
  }
]

export default {
  name: 'PanelMonitor',
  components: {
    PieChart,
    pieDetail
  },
  computed: {
    classes () {
      return isMobile() ? 'panel-monitor-mobile' : 'panel-monitor'
    }
  },
  data () {
    return {
      pieChartList: [],
      pieData: {},
      visible: false,
      columns,
      tableData: {}
    }
  },
  async mounted () {
    this.getPieData()
  },
  methods: {
    handlePieClick (params) {
      this.pieData = params.data
      this.getMessage()
    },
    async getMessage (params = {}) {
      const { platform, appVersion } = this.pieData
      const _params = {
        platform,
        appVersion,
        page_index: 1,
        page_size: 15,
        ...params
      }
      const { data } = await this.$apis.get(`/admin_app_version_detailed_list`, _params);
      this.tableData = data
      this.visible = true
    },
    async getPieData () {
      const {
        code,
        data,
        msg,
      } = await this.$apis
        .get(`/admin_count_app_version_users_list`, { page_index: 1, page_size: 10000 });

      const { macos, windows } = (data?.rows || []).reduce((acc, item) => {
        acc[item.platform.toLocaleLowerCase()].push(item)
        return acc
      }, { macos: [], windows: [] })

      this.pieChartList = [
        getVersionPieData('Mac OS', macos),
        getVersionPieData('Windows', windows),
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.panel-monitor {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.panel-monitor-mobile {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 30px;
}
</style>
