<template>
  <div class="home pages-store">
    <div class="flex-sw-center" style="height: 50px; width: 100%">
      <div class="flex-start-centere">
        <el-input
          placeholder="IP 地址"
          v-model.trim="parmes.ip"
          style="width: 180px; margin-right: 15px"
          @keydown.enter.native="getMessage()"
        ></el-input>
        <el-button
          class="h30"
          style="margin-left: 15px"
          type="success"
          size="mini"
          icon="el-icon-search"
          @click="getMessage(true)"
        >
          查询
        </el-button>
        <el-button
          class="h30"
          type="warning"
          size="mini"
          icon="el-icon-refresh"
          @click="
            reSetParmes();
            getMessage(true);
          "
        >
          重置
        </el-button>
      </div>

      <div class="flex-start-center" style="margin-left: 20px">
        <el-button
          class="h30"
          type="primary"
          size="mini"
          icon="el-icon-plus"
          @click="
            fromInit();
            from_type = `add`;
            dialogShow = true;
          "
        >
          新增
        </el-button>
      </div>
    </div>

    <div-table
      :loading="loading"
      :fromList="fromList"
      :fromData="fromData"
      :pageSize="pageSize"
      :pageValue="pageValue"
      :pageSizeList="pageSizeList"
      :total="total"
      @up-page-size="pageSize = $event"
      @up-page-value="pageValue = $event"
      @get-message="getMessage($event)"
    >
      <template v-slot:operate="{ data }">
        <div class="operate flex-center-center">
          <el-link
            :underline="false"
            type="danger"
            @click="changeList(data.rows, 'del')"
            >删除</el-link
          >
        </div>
      </template>
    </div-table>

    <el-dialog
      :title="from_type == 'add' ? '新增' : '编辑'"
      :visible.sync="dialogShow"
      width="30%"
    >
      <el-form lable-postition="right" ref="form" label-width="120px">
        <el-form-item
          v-for="(value, index) in showPageList(
            dialogList,
            ['ip']
          )"
          :key="index"
          :label="value.label"
        >
          <el-input
            style="margin-bottom: 10px"
            v-model="dialogFrom[value.key]"
            placeholder="请输入"
            :disabled="value.disabled || false"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="flex-end-center" style="margin-top: 15px">
        <el-button type="primary" @click="submit()"> 提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import divTable from "./components/div-table.vue";
export default {
  components: {
    divTable,
  },
  data() {
    return {
      uploadUrl: "",
      parmes: {},
      loading: false,
      fromList: [
        {
          label: "ID",
          key: "id",
          disabled: true,
        },
        {
          label: "IP 地址",
          key: "ip",
        },
        {
          label: "创建时间",
          key: "createTime",
        },
        {
          label: "更新时间",
          key: "updateTime",
        },
        {
          label: `操作`,
          key: `operate`,
          slot: `operate`,
          width: "100px",
        },
      ],
      fromData: [],
      pageSize: 15,
      pageSizeList: [3, 15, 200, 300, 400],
      pageValue: 1,
      total: 0,
      from_type: "add",
      dialogShow: false,
      dialogList: [
        {
          label: "ID",
          key: "id",
          disabled: true,
        },
        {
          label: "IP 地址",
          key: "ip",
        },
      ],
      addList: ["announcement", "href", "announcement_en"],
      editList: ["id", "announcement", "href", "announcement_en"],
      dialogFrom: {},
      options: [],
    };
  },
  created() {},
  async mounted() {
    this.getMessage();
  },

  watch: {},
  methods: {
    showPageList(arr, filterateArr) {
      let newArr = [];
      arr.forEach((item) => {
        filterateArr.indexOf(item.key) != -1 ? newArr.push(item) : "";
      });
      return newArr;
    },
    async submit() {
      this.loading = true;
      const { code, data, msg } = await this.$apis
        .post(`/admin_add_version_ip`,
          { ...this.dialogFrom }
        )
        .catch((err) => ({
          code: 1,
        }))
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        this.dialogShow = false;
        this.$message.success("操作成功");
        this.reSetParmes();
        this.getMessage();
      } else {
        this.$message.error(msg);
      }
    },
    delete(row, type) {
      this.$confirm("确定删除该IP？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.loading = true;
          const { code, data, msg } = await this.$apis
            .get("/admin_del_version_ip", {
              id: row.id,
            })
            .catch((e) => ({
              code: 1,
            }))
            .finally(() => {
              this.loading = false;
            });
          if (code == 0) {
            this.$message.success("操作成功");
            this.getMessage();
          } else {
            this.$message.error(msg);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async changeList(row, type) {
      if (type == "edit") {
        this.from_type = "edit";
        this.dialogShow = true;
        this.dialogFrom = Object.assign({}, row);
      } else if (type == "del") {
        this.delete(row, type);
      }
    },
    reSetParmes() {
      this.parmes = {};
    },
    fromInit(parmes = {}) {
      let obj = {};

      this.dialogList.forEach((elem) => {
        let { key, label } = elem;

        obj[key] = label || "";
      });
      this.dialogFrom = {};
    },
    async getMessage(boole = false) {
      if (boole) {
        this.pageValue = 1;
      }
      this.loading = true;
      const {
        code,
        data: { rows, total },
        msg,
      } = await this.$apis
        .get(`/admin_get_version_ip`, {
          page_index: this.pageValue,
          page_size: this.pageSize,
          ...this.parmes,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        // this.fromData = rows.filter((x) => x.status == 1);
        this.fromData = rows;
        this.total = total;
      } else {
        this.$message.error(msg);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  background-color: #fff;
}
.operate {
  justify-content: space-around;
}
.h30 {
  height: 30px;
}
.avatar {
  width: 36px;
}
</style>
