<template>
  <div class="dashboard-editor-container">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane class="tab-pane" v-loading="!data" lazy :label="item.label" :name="item.name"
        v-for="(item, index) in tabPanes" :key="index">
        <template v-if="item.component">
          <components :ref="item.name" :is="item.component" :data="data" :updateSize="item.updateSize"></components>
        </template>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import overview from './tab-overview';
import ranking from './tab-ranking';
import monitor from './tab-monitor';

export default {
  name: 'Dashboard',
  components: {
    overview,
    ranking,
    monitor
  },
  data () {
    return {
      activeName: 'first',
      tabPanes: [
        {
          label: '数据概览',
          name: 'first',
          component: 'overview',
          updateSize: false
        },
        {
          label: '用户排名',
          name: 'second',
          component: 'ranking',
          updateSize: false
        },
        {
          label: '版本监控',
          name: 'third',
          component: 'monitor',
          updateSize: false
        }
      ],
      data: null,
      timer: null,
    }
  },
  async mounted () {
    this.getLineData()
    this.timer = setInterval(() => {
      this.getLineData()
    }, 10000)
  },
  beforeDestroy () {
    clearInterval(this.timer)
  },
  methods: {
    async getLineData () {
      const { data } = await this.$apis.get(`/admin_get_sys_info`)
      this.data = data
    },
    handleClick (tab, event) {
      this.tabPanes.forEach(item => {
        this.$set(item, 'updateSize', tab.name === item.name)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-editor-container {
  padding: 10px 32px 32px;
  background-color: rgb(240, 242, 245);
  position: relative;
}

.tab-pane {
  min-height: 500px;
}

:deep(.el-tabs__item) {
  font-weight: 600;
}
</style>
