<template>
  <div class="home pages-store">
    <div class="flex-sw-center" style="height: 50px; width: 100%">
      <div class="flex-start-center">
        <el-input
          clearable
          placeholder="用户名搜索"
          v-model.trim="username"
          style="width: 180px"
          @keydown.enter.native="getUserList"
        ></el-input>
      </div>

      <div class="flex-start-center" style="margin-left: 20px">
        <el-button size="mini" type="primary" icon="el-icon-search" @click="getUserList">
          查询
        </el-button>
        <el-button
          size="mini"
          type="warning"
          icon="el-icon-refresh"
          @click="reset"
        >
          重置
        </el-button>
      </div>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%">
      <el-table-column
        prop="id"
        label="用户ID"
   >
      </el-table-column>
      <el-table-column
        prop="userName"
        label="用户名"
   >
      </el-table-column>

      <el-table-column
        prop="useWindowCount"
        label="已建窗口数量"
   >
      </el-table-column>
      <el-table-column
        prop="status"
        label="状态"
   >
        <template slot-scope="scope">
       {{ scope.row.status == 1 ? '正常' : '冻结' }}
      </template>
      </el-table-column>
      <el-table-column
        prop="adminName"
        label="开户人"
   >
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="开户时间">
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page_index"
      :page-sizes="[15, 50, 100, 200]"
      :page-size="page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        username: '',
        total: 0,
        page_size: 15,
        page_index: 1,
        tableData: [],
      };
    },
    mounted() {
      this.getUserList()
    },
    methods: {
      getUserList() {
        this.$apis.get('/admin_get_agent_user_list', {
          userName: this.username,
          page_size: this.page_size,
          page_index: this.page_index,
        }).then(res => {
          this.tableData = res.data.rows;
          this.total = res.data.total;
        })
      },
      reset() {
        this.username = ''
        this.getUserList()
      },
      handleSizeChange(val) {
        this.page_size = val
        console.log(`每页 ${val} 条`);
        this.getUserList()
      },
      handleCurrentChange(val) {
        this.page_index = val
        console.log(`当前页: ${val}`);
        this.getUserList()
      },
    }
  };
</script>
