import $apis from "@/api/index";
let a = [];
export default {
  getSystemDict () {
    let typeList = [];
    let systemDict = window.localStorage.getItem(`systemDict`)
      ? JSON.parse(window.localStorage.getItem(`systemDict`)) // 获取本地存储
      : {};
    $apis
      .get(`/admin_get_dict_version`, {}).then(res => {
        if (res.code == 0)
        {

          const diffKeys = Object.keys(res.data).filter((key) => systemDict[key] != res.data[key]);
          console.log(diffKeys);
          this.getInfo(diffKeys);

          // let data = res.data;
          // if (Object.keys(systemDict).length > 0)
          // {
          //   for (const key in data)
          //   {
          //     if (Object.hasOwnProperty.call(data, key))
          //     {
          //       data[key] != systemDict[key] ? typeList.push(key) : '';
          //     }
          //   }
          //   this.getInfo(typeList);
          // } else
          // {
          //   for (const key in data)
          //   {
          //     if (Object.hasOwnProperty.call(data, key))
          //     {
          //       systemDict[key] != data[key] ? typeList.push(key) : '';
          //     }
          //   }
          //   this.getInfo(typeList);
            window.localStorage.setItem(`systemDict`, JSON.stringify(res.data));
          // }
        }
      });
  },
 async getInfo (typeList) {
    let dicts = window.localStorage.getItem(`dicts`)
      ? (JSON.parse(window.localStorage.getItem(`dicts`))) // 获取本地存储
      : {};
    // 获取系统字典
    const promises = typeList.map(async (element) => {
     const { data } = await $apis.get(`/admin_get_system_dict`, { type: element })
        dicts[element] = data;
    });
    await Promise.all(promises);
    window.localStorage.setItem(`dicts`, JSON.stringify(dicts));
  }
};
