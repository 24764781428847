<template>
  <i class="el-icon-document-copy copy" @click="copyText"></i>
</template>

<script>
import { clipboardText } from '@/utils';
export default {
  name: 'CopyIcon',
  props: {
    value: {
      type: [String, Number, Object, Array],
      default: ''
    }
  },
  methods: {
    copyText () {
      clipboardText(this.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.copy {
  cursor: pointer;
}
</style>