var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "home pages-store" },
    [
      _c(
        "div",
        {
          staticClass: "flex-sw-center",
          staticStyle: { height: "50px", width: "100%" },
        },
        [
          _c(
            "div",
            {
              staticClass: "flex-start-center",
              staticStyle: { height: "50px", width: "100%" },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "180px", "margin-right": "15px" },
                  attrs: { placeholder: "升级类型", clearable: "" },
                  on: {
                    change: function ($event) {
                      return _vm.getMessage(true)
                    },
                  },
                  model: {
                    value: _vm.parmes.versionType,
                    callback: function ($$v) {
                      _vm.$set(_vm.parmes, "versionType", $$v)
                    },
                    expression: "parmes.versionType",
                  },
                },
                _vm._l(_vm.dictList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
              _c(
                "el-select",
                {
                  staticStyle: { width: "180px", "margin-right": "15px" },
                  attrs: { placeholder: "操作系统", clearable: "" },
                  on: {
                    change: function ($event) {
                      return _vm.getMessage(true)
                    },
                  },
                  model: {
                    value: _vm.parmes.os,
                    callback: function ($$v) {
                      _vm.$set(_vm.parmes, "os", $$v)
                    },
                    expression: "parmes.os",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "h30",
                  staticStyle: { "margin-left": "15px" },
                  attrs: {
                    type: "success",
                    size: "mini",
                    icon: "el-icon-search",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.getMessage(true)
                    },
                  },
                },
                [_vm._v(" 查询 ")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "h30",
                  attrs: {
                    type: "warning",
                    size: "mini",
                    icon: "el-icon-refresh",
                  },
                  on: {
                    click: function ($event) {
                      _vm.reSetParmes()
                      _vm.getMessage(true)
                    },
                  },
                },
                [_vm._v(" 重置 ")]
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "h30",
              staticStyle: { "margin-left": "15px" },
              attrs: { type: "primary", size: "mini", icon: "el-icon-plus" },
              on: {
                click: function ($event) {
                  _vm.fromInit()
                  _vm.from_type = `add`
                  _vm.dialogShow = true
                },
              },
            },
            [_vm._v(" 新增 ")]
          ),
        ],
        1
      ),
      _c("div-table", {
        attrs: {
          loading: _vm.loading,
          fromList: _vm.tableList,
          fromData: _vm.fromData,
          pageSize: _vm.pageSize,
          pageValue: _vm.pageValue,
          pageSizeList: _vm.pageSizeList,
          total: _vm.total,
        },
        on: {
          "up-page-size": function ($event) {
            _vm.pageSize = $event
          },
          "up-page-value": function ($event) {
            _vm.pageValue = $event
          },
          "get-message": function ($event) {
            return _vm.getMessage($event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "createTime",
            fn: function ({ data }) {
              return [
                _c("p", { staticClass: "openingTime" }, [
                  _vm._v(_vm._s(data.rows.createTime)),
                ]),
              ]
            },
          },
          {
            key: "updateTime",
            fn: function ({ data }) {
              return [
                _c("p", { staticClass: "openingTime" }, [
                  _vm._v(_vm._s(data.rows.updateTime)),
                ]),
              ]
            },
          },
          {
            key: "downloadUrl",
            fn: function ({ data }) {
              return [
                _c(
                  "p",
                  { staticClass: "openingTime" },
                  [
                    _vm._v(_vm._s(data.rows.downloadUrl)),
                    _c("icon-copy", {
                      staticClass: "copy-icon",
                      attrs: { value: data.rows.downloadUrl },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "operate",
            fn: function ({ data }) {
              return [
                _c(
                  "div",
                  { staticClass: "operate flex-sw-center" },
                  [
                    _c(
                      "el-link",
                      {
                        attrs: { underline: false, type: "warning" },
                        on: {
                          click: function ($event) {
                            return _vm.changeList(data.rows, "edit")
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-link",
                      {
                        attrs: { underline: false, type: "danger" },
                        on: {
                          click: function ($event) {
                            return _vm.changeList(data.rows, "del")
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.from_type == "edit" ? "编辑" : "新增",
            visible: _vm.dialogShow,
            width: "50%",
            "close-on-click-modal": false,
            "before-close": _vm.handleBeforeClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogShow = $event
            },
            close: _vm.handleClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: {
                "max-height": "60vh",
                "overflow-y": "auto",
                "padding-right": "15px",
              },
              attrs: { "lable-postition": "right", "label-width": "124px" },
            },
            _vm._l(_vm.formList, function (value) {
              return _c(
                "el-form-item",
                {
                  key: value.key,
                  attrs: {
                    label: value.label,
                    required: value.required == undefined ? false : true,
                  },
                },
                [
                  value.type == "select"
                    ? _c(
                        "el-select",
                        _vm._g(
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: "请选择",
                              disabled: value.disabled,
                            },
                            model: {
                              value: _vm.dialogFrom[value.key],
                              callback: function ($$v) {
                                _vm.$set(_vm.dialogFrom, value.key, $$v)
                              },
                              expression: "dialogFrom[value.key]",
                            },
                          },
                          value.events
                        ),
                        _vm._l(value.options, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      )
                    : value.key == "upgradeContent"
                    ? [
                        _c("WangEditor", {
                          model: {
                            value: _vm.dialogFrom[value.key],
                            callback: function ($$v) {
                              _vm.$set(_vm.dialogFrom, value.key, $$v)
                            },
                            expression: "dialogFrom[value.key]",
                          },
                        }),
                      ]
                    : value.key == "releaseTime" ||
                      value.key == "createTime" ||
                      value.key == "updateTime"
                    ? _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "date",
                          placeholder: "选择日期时间",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          disabled: value.disabled,
                        },
                        model: {
                          value: _vm.dialogFrom[value.key],
                          callback: function ($$v) {
                            _vm.$set(_vm.dialogFrom, value.key, $$v)
                          },
                          expression: "dialogFrom[value.key]",
                        },
                      })
                    : value.key == "forceUpgrade"
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { disabled: value.disabled, label: true },
                              model: {
                                value: _vm.dialogFrom[value.key],
                                callback: function ($$v) {
                                  _vm.$set(_vm.dialogFrom, value.key, $$v)
                                },
                                expression: "dialogFrom[value.key]",
                              },
                            },
                            [_vm._v("是")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { disabled: value.disabled, label: false },
                              model: {
                                value: _vm.dialogFrom[value.key],
                                callback: function ($$v) {
                                  _vm.$set(_vm.dialogFrom, value.key, $$v)
                                },
                                expression: "dialogFrom[value.key]",
                              },
                            },
                            [_vm._v("否")]
                          ),
                        ],
                        1
                      )
                    : value.key == "ipLimit"
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { disabled: value.disabled, label: true },
                              model: {
                                value: _vm.dialogFrom[value.key],
                                callback: function ($$v) {
                                  _vm.$set(_vm.dialogFrom, value.key, $$v)
                                },
                                expression: "dialogFrom[value.key]",
                              },
                            },
                            [_vm._v("启用")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { disabled: value.disabled, label: false },
                              model: {
                                value: _vm.dialogFrom[value.key],
                                callback: function ($$v) {
                                  _vm.$set(_vm.dialogFrom, value.key, $$v)
                                },
                                expression: "dialogFrom[value.key]",
                              },
                            },
                            [_vm._v("关闭")]
                          ),
                        ],
                        1
                      )
                    : value.key === "status"
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { disabled: value.disabled, label: true },
                              model: {
                                value: _vm.dialogFrom[value.key],
                                callback: function ($$v) {
                                  _vm.$set(_vm.dialogFrom, value.key, $$v)
                                },
                                expression: "dialogFrom[value.key]",
                              },
                            },
                            [_vm._v("生效")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { disabled: value.disabled, label: false },
                              model: {
                                value: _vm.dialogFrom[value.key],
                                callback: function ($$v) {
                                  _vm.$set(_vm.dialogFrom, value.key, $$v)
                                },
                                expression: "dialogFrom[value.key]",
                              },
                            },
                            [_vm._v("失效")]
                          ),
                        ],
                        1
                      )
                    : value.type == "upload"
                    ? _c("div", [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              position: "relative",
                              width: "100%",
                              height: "36px",
                              "text-align": "center",
                              "line-height": "36px",
                              color: "#2692ff",
                              border: "1px dashed #2692ff",
                              "border-radius": "6px",
                              overflow: "hidden",
                            },
                            attrs: { clsss: "upload-demo" },
                          },
                          [
                            _c("input", {
                              key: _vm.inputKey,
                              staticStyle: {
                                position: "absolute",
                                left: "0",
                                margin: "auto",
                                width: "100%",
                                height: "100%",
                                opacity: "0",
                                "z-index": "9",
                                cursor: "pointer",
                              },
                              style: {
                                cursor: _vm.disableUpload
                                  ? "not-allowed"
                                  : "pointer",
                              },
                              attrs: {
                                type: "file",
                                multiple: "",
                                disabled: _vm.disableUpload,
                              },
                              on: { change: _vm.beforeAvatarUpload },
                            }),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  position: "absolute",
                                  right: "0",
                                  left: "0",
                                },
                              },
                              [_vm._v(" 点击此处选择文件，系统会自动上传 ")]
                            ),
                          ]
                        ),
                        _vm.isShowProgress
                          ? _c(
                              "div",
                              { staticClass: "upload-list" },
                              _vm._l(_vm.uploadFileList, function (item) {
                                return _c(
                                  "div",
                                  {
                                    key: item.id,
                                    staticClass: "upload-list-item",
                                  },
                                  [
                                    _vm._v(" " + _vm._s(item.name) + " "),
                                    _c("el-progress", {
                                      attrs: { percentage: item.progress },
                                    }),
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                      ])
                    : _c("el-input", {
                        staticStyle: { "margin-bottom": "10px" },
                        attrs: {
                          type: value.type ? value.type : "text",
                          placeholder: "请输入",
                          disabled: value.disabled,
                          rows: value.rows,
                        },
                        model: {
                          value: _vm.dialogFrom[value.key],
                          callback: function ($$v) {
                            _vm.$set(_vm.dialogFrom, value.key, $$v)
                          },
                          expression: "dialogFrom[value.key]",
                        },
                      }),
                ],
                2
              )
            }),
            1
          ),
          _c(
            "div",
            {
              staticClass: "flex-end-center",
              staticStyle: { "margin-top": "15px" },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submit()
                    },
                  },
                },
                [_vm._v(" 提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: { title: "窗口详情", visible: _vm.drawer, size: "50%" },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [
          _vm.drawer
            ? _c(
                "div",
                {
                  ref: "drawer",
                  staticClass: "flex-d",
                  staticStyle: {
                    width: "calc(100% - 30px)",
                    margin: "0 15px",
                    padding: "5px 10px",
                  },
                },
                [
                  _c(
                    "el-descriptions",
                    {
                      attrs: {
                        title: "详细信息",
                        direction: "vertical",
                        border: "",
                      },
                    },
                    _vm._l(
                      _vm.fromList.filter((item) => item.key != "operate"),
                      function (item, index) {
                        return _c(
                          "el-descriptions-item",
                          { key: index, attrs: { label: item.label } },
                          [
                            _c(
                              "span",
                              {
                                style:
                                  item.trans &&
                                  item.trans(_vm.windowMessage[item.key]),
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.transValue == undefined
                                        ? _vm.windowMessage[item.key] || "-"
                                        : item.transValue(
                                            _vm.windowMessage[item.key],
                                            _vm.windowMessage
                                          )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        )
                      }
                    ),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }