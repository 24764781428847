<template>
  <div>
    <el-table
      v-loading="loading"
      :data="fromData"
      row-key="id"
      :height="H ? H : undefined"
      :row-class-name="tableRowClassNames()"
    >
      <el-table-column
        class-name="small-padding fixed-width"
        v-for="(value, index) in fromList"
        :key="index"
        :prop="value.key"
        :label="value.label"
        :width="value.width"
        :min-width="value.minWidth"
        align="center"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="data">
          <div
            v-if="value.slot == 'copy'"
            class="flex-center-center"
            style="margin: 0 auto"
          >
            <div
              class="flex-center-center"
              :style="
                value.trans ? value.trans(data.row) : 'display:inline-block'
              "
            >
              {{
                value.transValue == undefined
                  ? data.row[value.key] || `-`
                  : value.transValue(data.row[value.key], data.row, value) ||
                    "-"
              }}
            </div>
            <i
              v-show="!!data.row[value.key]"
              class="el-icon-document-copy"
              style="margin-left: 10px; cursor: pointer"
              @click="copyLink(data.row[value.key])"
            ></i>
          </div>
          <div
            v-if="value.slot == 'link'"
            class="flex-center-center"
            style="margin: 0 auto"
          >
            <div
              :style="value.trans(data.row)"
              class="flex-center-center"
              @click="value.click(data.row)"
            >
              {{
                value.transValue == undefined
                  ? data.row[value.key]
                  : value.transValue(data.row[value.key], data.row, value)
              }}
            </div>
          </div>
          <div
            v-else-if="value.slot == 'color'"
            class="flex-center-center"
            style="margin: 0 auto"
          >
            <div :style="value.trans(data.row)" class="flex-center-center">
              {{
                value.transValue == undefined
                  ? data.row[value.key]
                  : value.transValue(data.row[value.key], data.row, value)
              }}
            </div>
          </div>
          <div
            v-else-if="value.slot == 'id'"
            class="flex-center-center"
            style="margin: 0 auto; width: 100%"
          >
            <el-tooltip class="item" content="点击查看详情" placement="left">
              <div
                style="width: 100%"
                @click="value.click(data.row)"
                :style="value.trans(data.row)"
                class="flex-center-center"
              >
                {{ data.row[value.key] }}
              </div>
            </el-tooltip>
            <i
              v-show="value.enableCopy"
              class="el-icon-document-copy"
              style="margin-left: 10px; cursor: pointer"
              @click="copyLink(data.row[value.key])"
            ></i>
          </div>
          <div
            v-else-if="value.slot == 'icon'"
            class="flex-center-center"
            style="margin: 0 auto"
            label="图标"
          >
            <div v-if="data.row.type == `4`" class="flex-center-center">
              {{ busTypeMap[data.row.busType] }}
            </div>
            <img
              v-else-if="data.row.img != ``"
              style="
                width: 20px;
                height: 20px;
                border-radius: 50%;
                object-fit: cover;
              "
              :src="data.row.img"
            />
            <i
              style="font-size: 20px"
              v-else
              :class="`${$iconTrans(data.row[value.key])}`"
            ></i>
          </div>
          <slot
            v-else-if="value.slot != undefined"
            :name="value.slot"
            :data="{
              rows: data.row,
              keys: value,
            }"
          >
          </slot>

          <div
            :style="
              value.trans == undefined
                ? data.row[value.key]
                : value.trans(data.row[value.key], data.row)
            "
            v-else
          >
            {{
              value.transValue == undefined
                ? data.row[value.key] || "-"
                : value.transValue(data.row[value.key], data.row)
            }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="flex-end-center" style="margin-top: 15px" v-if="infoPage">
      <el-pagination
        :currentPage="pageValue"
        :page-size="pageSize"
        :page-sizes="pageSizeList"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    H: {
      typeof: String,
      default: 'calc(80vh  - 70px)',
    },
    infoPage: {
      typeof: Boolean,
      default: true,
    },
    loading: {
      typeof: Boolean,
      default: false,
    },
    fromList: {
      typeof: Array,

      default: () => [],
    },
    fromData: {
      typeof: Array,

      default: () => [],
    },
    pageSize: {
      typeof: Number,
      default: 15,
    },
    pageSizeList: {
      typeof: Number,
      default: () => [3, 15, 200, 300, 400],
    },
    pageValue: {
      typeof: Number,
      default: 1,
    },
    total: {
      typeof: Number,
      default: 0,
    },
    tableRowClassName: {
      typeof: Array,
      default: () => [],
    },
    trfreeze: {
      typeof: Function,
      default: () => {},
    },
  },
  methods: {
    copyLink(val) {
      if (val instanceof Array) {
        val = JSON.stringify(val);
      }
      // try {
      //   if (navigator.clipboard && window.isSecureContext) {
      //     // navigator clipboard 向剪贴板写文本
      //     navigator.clipboard
      //       .writeText(val)
      //       .then(this.$message.success("复制成功"));
      //   }
      // } catch (err) {
      //   this.$message.error("复制失败");
      // } finally {
      //   this.$message.error("复制失败");
      // }
      const textarea = document.createElement("textarea");
      textarea.setAttribute("readonly", "readonly");
      textarea.value = val;
      document.body.appendChild(textarea);
      textarea.select();
      if (document.execCommand) {
        document.execCommand("copy");
        this.$message.success("复制成功");
      } else {
        this.$message.success("复制失败");
      }
      document.body.removeChild(textarea);
    },
    tableRowClassNames() {
      // 表格斑马线设置
      return function ({ row }) {
        let { status } = row;
        return status == 2 || status == 0 ? `freeze` : ``;
      };
    },
    getMessage() {
      this.$emit("get-message", false);
    },
    handleSizeChange(val) {
      //   console.log(`${val} items per page`);
      this.$emit("up-page-size", val);
      this.getMessage();
    },
    handleCurrentChange(val) {
      // console.log(`current page --: ${val}`);
      this.$emit("up-page-value", val);
      this.getMessage();
    },
  },
};
</script>
<style lang="scss" scoped></style>
